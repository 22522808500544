
import { View } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class Tenants extends Mixins(View) {
  toggle(id: string) {
    const element = document.getElementById(id);

    if (element) {
      if (element!.className === '') {
        element!.classList.add('hide');
      } else {
        element!.classList.remove('hide');
      }
    }

    const element2 = document.getElementById(`icon${id}`);

    if (element2) {
      if (element2!.className === 'icon') {
        element2!.classList.add('up');
      } else {
        element2!.classList.remove('up');
      }
    }
  }
}
